import React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import LanguageSwitcher from '.././LanguageSwitcher/LanguageSwitcher';
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";


const Icon = styled(FontAwesomeIcon)`
    color: white;
    margin-right: 6px;
    ${props => props.$big && 'font-size:18px;'}
`;

const Nav = styled('nav')`
padding: 20px;

`;

const Container = styled('ul')`
list-style-type: none;
margin: 0;
padding: 0;
overflow: hidden;
position: relative;

`;

const Box = styled('li')`
     display: block;
     text-align: center;
     float: Left;
     cursor: pointer;

     a {
        text-decoration: none;
        color: white;
        font-weight: 800;
     }

`;

const Socials = styled('div')`
position: absolute;
left: 20px;
top: 50px;`;



const Navbar = () => {
    const { t } = useTranslation();
  return (
    <Nav>
        <Container>
        <a href="mailto:gogohomeproject@gmail.com">
            <Box>
                <Icon icon={faEnvelope} />
            </Box>
             </a>
            <Box style={{float: 'right'}}>
                <LanguageSwitcher />
            </Box>
        </Container>
        <Socials>
        <div><a href="https://www.facebook.com/p/GOGO-HOME-project-100084953250626/" target="_blank" rel="noreferrer">
            <Icon $big icon={faFacebook} />
        </a>
            </div>
        <div style={{marginTop: '10px'}}>
        <a href="https://www.instagram.com/gogohomeproject/" target="_blank" rel="noreferrer">
            <Icon $big icon={faInstagram} />
            </a>
            </div>
        </Socials>
    </Nav>
  );
};

export default Navbar;