import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            dates: {
              locationBerlinTime: 'Berlin (May 29th → July 3rd)',
              locationBerlin: 'Neuer Haim in the Volkspark Friedrichshain (entrance via Margarete-Sommer-Straße)',
              locationDresden: 'Alaunpark',
              locationDresdenTime: 'Dresden (August 2nd → September 1st)',
              firstBerlin: 'Saturday, June 29th 2024 - 3pm',
              secondBerlin: 'Saturday, June 29th 2024 - 6pm',
              thirdBerlin: 'Sunday, June 30th 2024 - 3pm',
              firstDresden: 'Thursday, August 22nd 2024 - 6pm',
              secondDresden: 'Friday, August 23rd 2024 - 6pm',
              thirdDresden: 'Saturday, August 24th 2024 - 6pm',
              fourthDresden: 'Monday, August 26th 2024 - 6pm',
              fifthDresden: 'Saturday, August 31st 2024 - 5pm',
              sixthDresden: 'Sunday, September 1st 2024 - 4pm',
            },
            text: {
              dates: 'Performance dates',
              title: 'The project',
              intro: 'The GOGO HOME project is a flying trapeze project founded in 2022 by siblings Katja and Moritz Haase in Berlin. With their full sized grand volant trapeze being set up temporarily in varying locations the project transforms public spaces for a limited amount of time into open-air stages where the fascination for flying is shared with everyone who passes by. the GOGO HOME project focuses on the artistic creation of flying trapeze performances inspired by the city of Berlin and its pulsating nightlife with an international cast of trapeze artists and interdisciplinary performers. \nThe spectacular performance is an open invitation to everyone to dive into that feeling of falling freely and take a sip of that intoxicating cocktail made of live performance, summer party and a good shot of adrenalin.', 
              videos: 'Videos'
            }
        }
      },
      de: {
        translation: {
            dates: {
              locationBerlinTime: 'Berlin (29. Mai → 3. Juli)',
              locationBerlin: 'Neuer Haim im Volkspark Friedrichshain (Eingang über Margarete-Sommer-Straße)',
              locationDresden: 'Alaunpark',
              locationDresdenTime: 'Dresden (2. August → 1. September)',
              firstBerlin: 'Samstag, 29. Juni 2024 - 15 Uhr',
              secondBerlin: 'Samstag, 29. Juni 2024 - 18 Uhr',
              thirdBerlin: 'Sonntag, 30. Juni 2024 - 15 Uhr',
              firstDresden: 'Donnerstag, 22. August 2024 - 18 Uhr',
              secondDresden: 'Freitag, 23. August 2024 - 18 Uhr',
              thirdDresden: 'Samstag, 24. August 2024 - 18 Uhr',
              fourthDresden: 'Montag, 26. August 2024 - 18 Uhr',
              fifthDresden: 'Samstag, 31. August 2024 - 17 Uhr',
              sixthDresden: 'Sonntag, 1. September 2024 - 16 Uhr',
            },
            text: {
              dates: 'Termine',
              title: 'Das Projekt',
              intro: 'Das Flugtrapezprojekt the GOGO HOME project, das von den Geschwistern Katja und Moritz Haase 2022 ins Leben gerufen wurde, widmet sich Open-Air-Performances am Großen Fliegenden Trapez, dem sogenannten Grand Volant. In den Sommermonaten ist das Trapez an wechselnden Standorten im öffentlichen Raum aufgebaut und verwandelt diesen in eine offene Bühne. Inspiriert vom weltoffenen Charakter Berlins mit seinem unbändigen Nachtleben wirbelt die internationale Truppe im Puls der Hauptstadt um die eindrucksvolle Kulisse. Die spektakuläre Performance ist eine öffentliche Einladung an alle, sich gemeinsam fallen zu lassen, für einen Augenblick die Freiheit des freien Falls zu genießen und einen Schluck des berauschenden Cocktails aus Live-Performance, Sommerparty und einem gehörigen Schuss Adrenalin zu kosten', 
              videos: 'Videos'
            }
        }
      }
    }
  });

export default i18n;