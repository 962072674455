import React from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { Container } from "../Intro/Intro";

const VideosContainer = styled('div')`
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    margin-top: 20px;

    @media (max-width: 1180px) {
        flex-direction: column;
        place-items: center;
      }

      @media (max-width: 576px) {
        iframe {
            width: auto;
        }
      }

      
`;


const Videos = () => {
  const { t } = useTranslation();
  return (
    <Container>
    <h2>{t('text.videos')}</h2>
    <VideosContainer>
    <iframe 
        width="460" 
        height="315" 
        src="https://www.youtube.com/embed/ZgnURTO-Lu4?si=jQfzj1t_ECnoHDho" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerpolicy="strict-origin-when-cross-origin" 
        allowfullscreen 
    />
    <iframe 
        width="460" 
        height="315" 
        src="https://www.youtube.com/embed/dj6DXqRXLc4?si=AI44u0nhxZeMo4Qi" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerpolicy="strict-origin-when-cross-origin" 
        allowfullscreen 
    />
    <iframe 
        width="460" 
        height="315" 
        src="https://www.youtube.com/embed/H9Oc-4O_swg?si=f6ZLZz9olnzYcqqP" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerpolicy="strict-origin-when-cross-origin" 
        allowfullscreen 
    />
    </VideosContainer>
    </Container>
  );
};

export default Videos;