import logo from './img/logo.jpeg';
import './App.css';
import Header from './components/Header';
import Dates from './components/Dates';
import Intro from './components/Intro/Intro';
// import LanguageSwitcher from './components/LanguageSwitcher/LanguageSwitcher';
import Navbar from './components/Navbar/Navbar';
import Videos from './components/Videos';


function App() {
  return (
    <>
    <div style={{position: "fixed", width: '100%'}}>
      <Navbar />
    </div>
      <Header />
      <div className='ggh'>
        <div className='prova'>
        <Intro />
        <Dates />
        <Videos />
        </div>
      </div>
      </>
  );
}

export default App;
