import React from "react";
import styled from "styled-components";
import img from '../../img/home.jpg';
import logo from '../../img/biglogo.png'

// const Button = styled('button')`
//   color: #FFF;
//   transition: all 0.5s;
//   position: relative; 
//   background: none;
//   outline: none;
//   border: none;
//   padding: 5px 25px;
//   font-weight: 600;
//   cursor: pointer;
//   font-size: 16px;
//   width: 100%;


//   span {
//     z-index: 2; 
//     display: block;
//     position: absolute;
//     width: 100%;
//     height: 100%; 
//     }

//   &:before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 1;
//     transition: all 0.5s;
//     border: 1px solid rgba(255,255,255,0.2);
//     background-color: rgba(255,255,255,0.1);
//   }
//   &:after {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 1;
//     transition: all 0.5s;
//     border: 1px solid rgba(255,255,255,0.2);
//     background-color: rgba(255,255,255,0.1);
//   }

//   &:hover::before {
//     transform: rotate(-45deg);
//     background-color: rgba(255,255,255,0);
//   }
//   &:hover::after {
//     transform: rotate(45deg);
//     background-color: rgba(255,255,255,0);
//   }

//   &:hover {
//     color: orange;
//   }
// `;
const HeroSection = styled.section`
  background-position: center;
  background-size: cover;
  height: fit-content;
  color: #fafafc;
  padding: 500px 3rem 6rem;
  .heroInner {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
  }

  h1 {
    font-weight: 900;
    font-size: clamp(2rem, 5.5vw, 3.25rem);
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 1180px) {
    padding: 400px 3rem 6rem;
  }

  @media (max-width: 910px) {
    padding: 200px 3rem 6rem;
  }
  
  @media (max-width: 576px) {
    padding: 320px 3rem 6rem;
  }
`;

const Logo = styled('img')`
  max-width: 500px;
  @media (max-width: 576px) {
    max-width: 250px;
  }

`;

const ContainerLogo = styled('span')`
  position: absolute;
  top: 750px;

  

  @media (max-width: 1180px) {
    top: 640px;
  }

  @media (max-width: 910px) {
    top: 500px;
  }

  @media (max-width: 576px) {
    top: 250px;
  }

`;

const Header = () => {
  return (
    <div>
      <HeroSection className="light hero">
        <div className="heroInner">
          <ContainerLogo>
           <Logo src={logo}  />
            {/* <Button>
              Explore Now
            </Button> */}
          </ContainerLogo>
        </div>
      </HeroSection>
    </div>
  );
};

export default Header;