import React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const LangSwitcher = styled.select`
background: transparent;
border: 1px solid white;
color: white;
padding: 5px;
&:focus {outline:0 !important;}
border-radius: 15px;

option > {
  font-weight: 800;
}
`;



const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const lngs = {
        en: { nativeName: 'English' },
        de: { nativeName: 'Deutsch' }
      };
  return (
    <LangSwitcher onChange={(e) => i18n.changeLanguage(e.target.value)}>
          {Object.keys(lngs).map((lng) => (
            <option key={lng} value={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }}>
              {lngs[lng].nativeName}
            </option>
          ))}
        </LangSwitcher>
  );
};

export default LanguageSwitcher;