import React from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import img from '../../img/dates.jpg';
import { Container } from "../Intro/Intro";


const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: 1180px) {
        margin-top: 10px;
    }
`;

const DatesContainer = styled('div')`
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 15px;
    margin-top: 20px;

    @media (max-width: 1180px) {
        display: flex;
        flex-direction: column;
      }
`;

const Pic = styled('div')`
    background: url(${img});
    background-size: cover;
    border-radius: 15px 0 0 15px;
    height: unset;

    @media (max-width: 1180px) {
        height: 400px;
        border-radius:0;
        background-position: center;
        margin-top: 10px;
      }
`;

const Location = styled('div')`
    border: 1px solid ${props => props.$dateColor};
    padding: 10px 20px;
    color: white; 
    text-align: center;
    border-radius: ${props => props.$border && '0 15px 0 0'};

    small {
        color: white;
        font-weight: 600;
        display: block;
    }

    @media (max-width: 1180px) {
        border-radius:0;
      }


`;

const SingleDate = styled('div')`
    background: ${props => props.$dateColor};
    padding: 10px 20px;
    border-radius: ${props => props.$border && '0 0 15px 0'};
    text-align: center;
    
    &:hover {
        opacity: 0.9;
        transform: scale(1.02);
    }

    @media (max-width: 1180px) {
        border-radius:0;
      }
`;

const Dates = () => {
  const { t } = useTranslation();
  return (
    <Container>
    <h2>{t('text.dates')}</h2>
    <DatesContainer>
      <Pic />
      <List>
        {/* <Location $border $dateColor='#1dc0e1'>
            <h3>{t('dates.locationBerlinTime')}</h3>
            <small>{t('dates.locationBerlin')}</small>
        </Location>
        <SingleDate $dateColor='#1dc0e1' ><h4>{t('dates.firstBerlin')}</h4></SingleDate>
        <SingleDate $dateColor='#1dc0e1' ><h4>{t('dates.secondBerlin')}</h4></SingleDate>
        <SingleDate $dateColor='#1dc0e1' ><h4>{t('dates.thirdBerlin')}</h4></SingleDate> */}
        <Location $dateColor='#1d9de1'>
            <h3>{t('dates.locationDresdenTime')}</h3>
            <small>{t('dates.locationDresden')}</small>
        </Location>
        <SingleDate $dateColor='#333' ><h4>{t('dates.firstDresden')}</h4></SingleDate>
        <SingleDate $dateColor='#333' ><h4>{t('dates.secondDresden')}</h4></SingleDate>
        <SingleDate $dateColor='#333' ><h4>{t('dates.thirdDresden')}</h4></SingleDate>
        <SingleDate $dateColor='#1d9de1' ><h4>{t('dates.fourthDresden')}</h4></SingleDate>
        <SingleDate $dateColor='#1d9de1' ><h4>{t('dates.fifthDresden')}</h4></SingleDate>
        <SingleDate $border $dateColor='#1d9de1' ><h4>{t('dates.sixthDresden')}</h4></SingleDate>

      </List>
    </DatesContainer>
    </Container>
  );
};

export default Dates;