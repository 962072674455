import React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

export const Container = styled(`div`)`
  background: #000000d6;
  padding: 30px 50px 40px 50px;
  border-radius: 15px;

  @media (max-width: 576px) {
    padding: 20px;
  }
  `;

const Text = styled.p``;

const Intro = () => {
    const { t } = useTranslation();
  return (
    <Container>
    <h2>{t('text.title')}</h2>
    <Text>
        {t('text.intro')}
    </Text>
    </Container>
  );
};

export default Intro;